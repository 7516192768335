

// //*----------------------------- bootstrap carousel ------------------*//

.carousel-indicators {
  right: unset;
  margin-left: 1.2rem;

  @include media-breakpoint-down(lg){
    transform: translateY(6rem);
    right: 0;
    margin-left: 15%;
  }

  [data-bs-target]{
    background-color: $dark;
    width: toRem(11);
    height: toRem(11);
    opacity: 0.3;
    border-radius: 50%;
    
    &:not(:last-child){
      margin-right: toRem(25);
    }

    &.active {
      opacity: $carousel-indicator-active-opacity;
    }
  } 
}

.carousel-inner{
  overflow: unset;
}