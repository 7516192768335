/*-----------------------------------------------
|   Icons group
-----------------------------------------------*/
.icon-group {
  display: flex;
  .icon-item:not(:last-child) {
    margin-right: map_get($spacers, 4);
  }
}
.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #080809;
  background-color: $gray-300;
  transition: $transition-base;
  height: 2.5rem;
  width: 2.5rem;
  border: 0;
  font-size: $font-size-sm;
  
  &#facebook{
    background: $white;
    @include hover-focus {
      background-color: $facebook;
    }
  }

  &#instagram{
    background: $white;
    @include hover-focus {
      background-image: $instagram;
    }
  }

  &#twitter{
    background: $white;
    @include hover-focus {
      background-color: $twitter;
    }
  }

  @include hover-focus {
    color: $white;
  }

  &.icon-item-sm {
    height: 1.875rem;
    width: 1.875rem;
  }
  
  &.icon-item-lg {
    height: 2.75rem;
    width: 2.75rem;
  }
}
.fontawesome-i2svg-active {
  .icon-indicator {
    opacity: 1;
  }
}

